<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-window"/>
          Einen neuen Kunden anlegen
        </CCardHeader>

        <CCardBody>
          <NeuerKunde v-model="kunde"/>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import NeuerKunde from '@/components/NeuerKunde'

export default {
  name: 'MitarbeiterNew',
  components: {
    NeuerKunde
  },
  data () {
    return {
      kunde: null
    }
  },
  watch: {
    kunde: function () {
      if (this.kunde) {
        this.$router.push({ path: '/kunden/uebersicht' })
      }
    }
  }
}
</script>
